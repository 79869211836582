// poppins fonts

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'rsuite/dist/rsuite.css';

@import './header.scss';
@import './sidebar.scss';
@import './login.scss';
@import './loader.scss';

:root {
  --color-green: #81a649;
  --color-light-green: #7ddd9f;
  --color-black: #172432;
  --color-grey: #767e86;
  --color-white: #ffffff;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;

    &.hover {
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #81a649;
    border-radius: 50px;
  }
}
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #f9f9f9;
}

a {
  text-decoration: none !important;
  color: #fff;
}

img {
  max-width: 100%;
}

nav ul li {
  a {
    transition: all 0.3s ease-in;
  }
}

nav ul li:hover {
  a {
    color: #81a649 !important;
  }
}

.transition-sticky {
  transition: background-color 0.5s, top 0.5s;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease forwards;
}

.animate-slideOut {
  animation: slideOut 0.5s ease forwards;
}

.section {
  padding: 80px 0px;
}

// error classes
.error {
  color: red !important;
  font-size: 14px !important;
}
.field-error {
  fieldset {
    border-color: red !important;
  }
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

// --------------------

.text-primary {
  color: var(--color-black);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-bottom: 3px solid #81a649;
  display: inline-block;
  padding-bottom: 12px;
}
.text-secondary {
  color: var(--color-grey);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
}
// .border-green{
// background-color: #81A649;
// width: 250px;
// height: 3px ;
// display: block;
// margin-top: 12px;
// }

.slider-action {
  margin-top: 24px;
  display: flex;
  gap: 12px;
}
.slider-action .btn-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: #172432;
  cursor: pointer;
}
.slider-action .btn-next {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: #81a649;
  cursor: pointer;
}
.slider-action svg {
  color: #fff;
}
.review-section .slick-slide {
  padding: 0 18px;
}
@media (max-width: '640px') {
  .review-section .slick-slide {
    padding: 0px 8px;
  }
}

@media (max-width: 767px) {
  nav {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .text-primary {
    font-size: 32px;

    padding-bottom: 8px;
  }
  .text-secondary {
    font-size: 20px;
    margin-top: 20px;
  }
  .slider-action {
    margin-top: 20px;
    gap: 10px;
  }
  .slider-action .btn-prev {
    padding: 8px 10px;
    border-radius: 8px;
  }
  .slider-action .btn-next {
    padding: 8px 10px;
    border-radius: 8px;
  }
  .border-green {
    width: 250px;
    margin-top: 12px;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .text-primary {
    font-size: 28px;

    padding-bottom: 6px;
  }
  .text-secondary {
    font-size: 18px;
    margin-top: 16px;
  }
  .slider-action {
    margin-top: 16px;
    gap: 8px;
  }
  .slider-action .btn-prev {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .slider-action .btn-next {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .border-green {
    width: 200px;
    margin-top: 12px;
  }
}

@media (min-width: 576px) and (max-width: 640px) {
  .text-primary {
    font-size: 28px;

    padding-bottom: 6px;
  }
  .text-secondary {
    font-size: 16px;
    margin-top: 12px;
  }
  .slider-action {
    margin-top: 12px;
    gap: 6px;
  }
  .slider-action .btn-prev {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .slider-action .btn-next {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .border-green {
    width: 150px;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .text-primary {
    font-size: 24px;

    padding-bottom: 6px;
  }
  .text-secondary {
    font-size: 14px;
    margin-top: 8px;
  }
  .slider-action {
    margin-top: 8px;
    gap: 8px;
  }
  .slider-action .btn-prev {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .slider-action .btn-next {
    padding: 6px 8px;
    border-radius: 6px;
  }
  .border-green {
    width: 100px;
    margin-top: 8px;
  }
}

.modal-screencast-slider {
  // width: 70%;
  // height: 80%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  text-align: center;
  // background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;

  background-color: #000;

  img {
    // width: 100%;
    // height: 100% !important;
    width: auto;
    height: calc(100vh - 10px);

    // height: fit-content;
    // background-size: contain;
    // background-clip: border-box;
    //   max-width: 900px;
    max-height: 900px;
    //   margin-bottom: 5px;
    border-radius: 10px;
    @media (max-width: 768px) {
      height: auto;
    }
  }
  .modal-close {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 25px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
    svg {
      color: var(--color-black);
      font-size: 24px;
      transform: rotate(45deg);
    }
  }
}

.package {
  position: relative;

  &:hover::before {
    content: 'View Details';
    // background: #1724324b;
    background-color: #2b20205d;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 45%;
    width: 90%;

    position: absolute;
    top: 0;
    z-index: 5;

    color: var(--color-white);
    font-size: 24px;
    font-weight: bold;
  }
  @media (max-width: 640px) {
    &:hover::before {
      width: 100%; /* Set the width to 100% below 640px */
    }
  }
}

.package-2 {
  position: relative;

  &:hover::before {
    content: 'Get More...';
    // background: #1724324b;
    background-color: #2b202075;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 43.2%;
    width: calc(100% - 18px);

    position: absolute;
    top: 9px;
    left: 9px;
    z-index: 5;

    color: var(--color-white);
    font-size: 24px;
    font-weight: bold;
  }

  @media (max-width: 640px) {
    &:hover::before {
      width: calc(100% - 20px); /* Set the width to 100% below 640px */
    }
  }
}

.destination-slider {
  .slick-slide > div:first-child {
    margin-right: 2px;
  }

  // Media Query for screens with a max width of 768px (typical for mobile devices)
  @media (min-width: 576px) and (max-width: 768px) {
    .slick-slide > div:first-child {
      margin-right: 10px; // Adjust the margin for smaller screens
    }
  }

  // Media Query for screens with a min width of 769px and a max width of 1024px (typical for tablets)
  @media (min-width: 769px) {
    .slick-slide > div:first-child {
      margin-right: 20px; // Adjust the margin for tablet screens
    }
  }
  // Add more media queries as needed for other device sizes
}

// ---------------------------------------------------------------------------admin site css start
// card
.card {
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 16px;

  .card-title-bar {
    margin-bottom: 12px;

    .card-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
    }

    .see-all {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }
  }
}

.btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 30px;
  height: 45px;
  max-width: 300px;
  width: 100%;
  border-radius: 6px !important;
  border: 1px solid var(--color-green);
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize !important;
  color: var(--color-white);
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;

  &.btn-primary {
    background-color: var(--color-green) !important;
    border: 1px solid var(--color-green);
    color: var(--color-white);
  }

  &.btn-secondary {
    background-color: var(--color-grey) !important;
    border: 1px solid var(--color-grey);
    color: var(--color-white);
  }

  &.btn-cancel {
    border: 1px solid var(--color-grey);
    color: var(--color-black);
  }

  &.btn-tertiary {
    background-color: var(--color-black) !important;
    border: 1px solid var(--color-black);
    color: var(--color-white);
  }
}

.table-wrapper {
  width: 100%;
  overflow-x: scroll;

  table {
    width: 100%;
    thead {
      background-color: var(--color-black);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      tr {
        th {
          white-space: nowrap;
          font-family: 'Poppins', sans-serif;
          color: var(--color-white);
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          padding: 10px 20px;
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 400;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          line-height: 27px;
          color: var(--color-grey);
          text-align: left;
          padding: 10px 20px;
          border-bottom: 1px solid rgba(119, 119, 119, 0.2);
          // white-space: nowrap; /* Prevent line breaks */
        }
        &:not(.exclude-wrap) {
          white-space: wrap !important;
        }
      }
    }
  }

  tbody {
    tr {
      &.Mui-selected,
      &:hover {
        background-color: #f5f5f5 !important;
      }
    }
  }
  .table-action-btn {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 18px;
      overflow: inherit;
    }
    button {
      width: 30px;
      max-width: 30px;
      min-width: 30px;
      height: 30px;
    }
  }
}

// table
.table-wrapper {
  padding-bottom: 5px;
  .inquiry-table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            min-width: 60px;
          }
          &:nth-child(2) {
            min-width: 125px;
          }
          &:nth-child(3) {
            min-width: 170px;
          }
          &:nth-child(4) {
            min-width: 180px;
          }
          &:last-child {
            min-width: 100px;
            max-width: 100px;
            text-align: center;
          }
        }
      }
    }
  }
}

.swal2-container {
  z-index: 9999;
}

// modal style
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-white);
  min-height: calc(100% - 64px);
  border-radius: 10px;
  width: 100%;
  max-width: 564px;
  padding: 20px;
  overflow: auto;

  .modal-close {
    font-size: 20px;
    right: 15px;
    top: 15px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-white);
    border-radius: 100%;

    svg {
      font-size: 25px;
      color: var(--color-grey);
      transform: rotate(45deg);
    }
  }

  &.modal-bg {
    background-color: var(--color-white);
    min-height: 100px;
    max-height: calc(100% - 64px);
    height: auto;
  }

  .modal-body {
    margin-top: 20px;
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: red;
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: rgb(47, 255, 0);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2);
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2);
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2);
  }
  .confirmation-text {
    background: rgba(25, 118, 210, 0.04);
    border: 1px solid var(--color-black);
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }

    .project-name {
      margin-top: 5px;
    }

    .confirmation-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: block;
    }

    .confirmation-sub-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      b {
        font-weight: 500 !important;
      }
    }
  }
  textarea {
    border: 1px solid var(--color-grey);
    border-radius: 10px;
    padding: 15px;
    min-height: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
  }
  .modal-footer {
    margin-top: 40px;

    .btn {
      max-width: 100%;
    }
  }
}

.top-bar {
  margin-bottom: 16px;

  .filter-select-options {
    width: 160px;
  }

  .btn {
    max-width: auto !important;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    // font-size: 14px !important;
    line-height: 24px;

    svg {
      margin-right: 8px;
    }
  }
}

.rs-stack-item {
  display: flex !important;
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-picker-daterange-calendar-group .rs-calendar:last-child {
  display: none;
}

.rs-picker-daterange-calendar-group {
  min-width: auto;
}

.rs-picker-menu {
  z-index: 9999 !important;
}
.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-menu .rs-picker-toolbar-ranges {
  flex-direction: column;
}

.rs-picker-toggle {
  border: 1px solid var(--color-grey);
  border-radius: 10px !important;
}
.rs-picker-toggle:hover {
  border-color: var(--color-green) !important;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
  border-color: var(--color-green) !important;

  &:hover {
    border-color: var(--color-green) !important;
  }
}

.rs-btn-icon {
  background: var(--color-green);
  border: 1px solid var(--color-green);
  border-radius: 5px;
  padding: 0;
}

.rs-btn-icon svg {
  color: var(--color-white);
  font-size: 20px !important;

  &:hover {
    fill: var(--color-green) !important;
  }
}

.rs-calendar-header-title {
  margin: 0 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
}

.rs-calendar-table-header-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content {
  background: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--color-green);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color-green) !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell:hover {
  background-color: transparent !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-in-range::before {
  background: rgba(140, 199, 20, 0.3);
  border-top: 1px solid var(--color-green);
  border-bottom: 1px solid var(--color-green);
}

.rs-stack-item {
  width: 100%;
  text-align: center;
}

.rs-stack-item .rs-btn-link {
  width: 171px;
  text-align: center;
  border: 1px solid var(--color-green);
  border-radius: 5px;
  color: var(--color-black) !important;
  text-decoration: none !important;
}

.rs-stack-item .rs-btn-link:hover {
  background: rgba(140, 199, 20, 0.3);
}

.rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
  width: 100%;
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  top: 5px;
}

.rs-picker-toolbar-right {
  width: 100%;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-year-active {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
}

.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-toolbar .rs-stack-item {
  max-width: 171px;
}

.rs-picker-toggle-read-only {
  opacity: 1;
}
