//  login page design
.login-container {
  font-family: 'Poppins', sans-serif !important;
  background: rgb(129, 166, 73);
  background: linear-gradient(90deg, rgba(129, 166, 73, 1) 21%, rgba(118, 126, 134, 1) 50%, rgba(23, 36, 50, 1) 71%);
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .login-box {
    background-color: var(--color-white);
    border: 0px solid #000;
    margin-top: 22px;
    max-width: 550px;
    padding: 10px 22px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding: 0px 20px;

      p {
        text-align: right;
        color: var(--color-grey);
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
      }
    }

    .signin-btn {
      word-spacing: 15px;
      letter-spacing: 5px;
      font-family: 'Poppins', sans-serif !important;
      width: 100%;
      height: 42px;
      color: var(--color-white) !important;
      background-color: var(--color-green) !important;
      border-radius: 6px !important;

      &:hover {
        background-color: var(--color-green) !important;
      }
    }
  }
}
